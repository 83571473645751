export const PAYMENTDETAILConstants = {
    GETALL_PAYMENTDETAIL_REQUEST: 'GETALL_PAYMENTDETAIL_REQUEST',
    GETALL_PAYMENTDETAIL_SUCCESS: 'GETALL_PAYMENTDETAIL_SUCCESS',
    GETALL_PAYMENTDETAIL_FAILURE: 'GETALL_PAYMENTDETAIL_FAILURE',


    UPDATE_STATUS_PAYMENTDETAIL_REQUEST: 'UPDATE_STATUS_PAYMENTDETAIL_REQUEST',
    UPDATE_STATUS_PAYMENTDETAIL_SUCCESS: 'UPDATE_STATUS_PAYMENTDETAIL_SUCCESS',
    UPDATE_STATUS_PAYMENTDETAIL_FAILURE: 'UPDATE_STATUS_PAYMENTDETAIL_FAILURE',
};
