export const fundConstants = {
    GETALL_FUND_REQUEST: 'GETALL_FUND_REQUEST',
    GETALL_FUND_SUCCESS: 'GETALL_FUND_SUCCESS',
    GETALL_FUND_FAILURE: 'GETALL_FUND_FAILURE',


    UPDATE_STATUS_FUND_REQUEST: 'UPDATE_STATUS_FUND_REQUEST',
    UPDATE_STATUS_FUND_SUCCESS: 'UPDATE_STATUS_FUND_SUCCESS',
    UPDATE_STATUS_FUND_FAILURE: 'UPDATE_STATUS_FUND_FAILURE',
};
