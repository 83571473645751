import { fundConstants } from "../_constants/fund.constants";

export function fund(state = {}, action) {
   //console.log("action.typeaction.typeaction.type  ", action.withdraw);
console.log("fundddddddddddddddddddddddddddddddddddddddd",action.fund);
  switch (action.type) {
    case fundConstants.GETALL_FUND_REQUEST:
      return {
        loading: true,
        items:state.items,
      };
    case fundConstants.GETALL_FUND_SUCCESS:
      return {
        items: action.fund.listOfFunds,
        // total: action.fund.listOfFunds,
      };
    case fundConstants.GETALL_FUND_FAILURE:
      return {
        loading: false,
        error: action.error
      };

    case fundConstants.UPDATE_STATUS_FUND_REQUEST:
      return {
        loading: true,
        items:state.items,
        total:state.total,
      };
    case fundConstants.UPDATE_STATUS_FUND_SUCCESS:
      return {
        isUpdate: true,
        items: state.items,
        total:state.total,
      };
    case fundConstants.UPDATE_STATUS_FUND_FAILURE:
      return {
        loading: false,
        error: action.error
      };
    default:
      return state
  }
}