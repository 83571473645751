import { PAYMENTDETAILConstants } from "../_constants/Paymentdetail.constants";

export function Paymentdetail(state = {}, action) {
   //console.log("action.typeaction.typeaction.type  ", action.withdraw);
console.log("=============>>>>>>>>>>>>>>>action",action.payment , PAYMENTDETAILConstants);
  switch (action.type) {
    case PAYMENTDETAILConstants.GETALL_PAYMENTDETAIL_REQUEST:
      return {
        loading: true,
        items:state.items,
      };
    case PAYMENTDETAILConstants.GETALL_PAYMENTDETAIL_SUCCESS:
      return {
        items: action.payment.listOfPayments,
        // total: action.PAYMENTDETAIL.listOfPAYMENTDETAILs,
      };
    case PAYMENTDETAILConstants.GETALL_PAYMENTDETAIL_FAILURE:
      return {
        loading: false,
        error: action.error
      };
    case PAYMENTDETAILConstants.UPDATE_STATUS_PAYMENTDETAIL_REQUEST:
      return {
        loading: true,
        items:state.items,
        total:state.total,
      };
    case PAYMENTDETAILConstants.UPDATE_STATUS_PAYMENTDETAIL_SUCCESS:
      return {
        isUpdate: true,
        items: state.items,
        total:state.total,
      };
    case PAYMENTDETAILConstants.UPDATE_STATUS_PAYMENTDETAIL_FAILURE:
      return {
        loading: false,
        error: action.error
      };
    default:
      return state
  }
}